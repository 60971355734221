const defaultItem = {
  title: "Section Title",
  subtext: "description...",
  imageDescription: "short description of image...",
  id: Math.random().toString(36).substr(2, 9),
}

export default {
  id: 0,
  label: "Image Sections",
  fields: [
    {
      name: "rawJson.sections",
      component: "group-list",
      label: "Image Sections",
      defaultValue: [
        {
          title: "Section Title",
          subtext: "description...",
        },
      ],
      parse(value) {
        console.log("looking for default")
        console.log(value, "value")
        return value || [defaultItem]
      },
      itemProps: item => ({
        key: item.id,
        label: item.title,
      }),
      defaultItem: () => defaultItem,
      fields: [
        {
          label: "Title",
          name: "title",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Subtext",
          name: "subtext",
          component: "textarea",
          parse(value) {
            return value || ""
          },
        },
        {
          label: `Image`,
          name: `image`,
          component: `image`,
          description: "480px X 640px",
          parse: filename => `../img/${filename}`,
          previewSrc: (formValues, { input }) => {
            const [, field, index] = input.name.split(".")
            const node = formValues.jsonNode?.[field]?.[parseInt(index)]
            return node?.image?.childImageSharp?.fluid?.src || ""
          },
          uploadDir: () => `/content/img/`,
        },
        {
          label: "Image Description",
          description: "This adds to the websites SEO",
          name: "imageDescription",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
      ],
    },
  ],
}

import React from "react"

import Layout from "../components/Layout"
import ImageSection from "../components/ImageSection"
import SEO from "../components/SEO"
import Hero from "../components/Hero"

const IndexPage = () => (
  <Layout>
    <SEO title="Ice Age - steel line pipe for ski resorts" />
    <Hero />
    <ImageSection />
  </Layout>
)

export default IndexPage

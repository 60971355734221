import React from "react"
import styled from "styled-components"

const Root = styled.span`
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid ${props => props.theme.colors.yellow};
    border-radius: 30px;
    top: -12px;
    left: -12px;
  }

  & > .gatsby-image-wrapper,
  & > img {
    height: auto;
    width: 100%;
    border-radius: 30px;
    ${props => props.theme.mixins.shadow};
  }
`

const Image = ({ className, children }) => {
  return <Root className={className}>{children}</Root>
}

export default Image

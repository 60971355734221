import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useLocalJsonForm, useGlobalJsonForm } from "gatsby-tinacms-json"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "./Button"
import Image from "./Image"
import heroBg from "../images/hero-bg.svg"
import heroBgLine from "../images/hero-bg-line.svg"
import heroForm from "../../content/forms/hero"
import settingsForm from "../../content/forms/settings"

const Root = styled.section`
  position: relative;
  width: 100%;
  height: 900px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    display: block;
    height: 100%;
    background-repeat: no-repeat;

    ${breakpoint("md")`
      background-position-x: right;
    `}
    ${breakpoint("lg")`
      right: 80px;
    `}
  }

  &::before {
    top: 0;
    left: -3px;
    background-image: url(${heroBg});
    ${breakpoint("lg")`
      right: 80px;
    `}
  }

  &::after {
    top: -20px;
    left: -20px;
    background-image: url(${heroBgLine});
    ${breakpoint("md")`
      right: -30px;
    `}
    ${breakpoint("lg")`
      right: 50px;
    `}
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 20px 15px 80px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: visible;

  ${breakpoint("sm")`
    padding-right: 30px;
    padding-left: 30px;
  `}

  ${breakpoint("md")`
    flex-direction: row;
    margin: 0 auto;
    padding: 120px 30px 0;
  `}
`

const Half = styled.div`
  ${breakpoint("md")`
    width: 50%;
  `}
`

const ImageHalf = styled(Half)`
  /* display: none; */
  overflow: visible;
  text-align: center;

  ${breakpoint("md")`
    order: 2;
    display: block;
  `}
`

const HeroImage = styled(Image)`
  border-radius: 37px;
  height: auto;
  width: 100%;

  ${breakpoint("md")`
    width: 889px;
  `}
`

const Title = styled.h1`
  color: #fff;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-right: 20px;
`

const Subtext = styled.p`
  color: #fff;
  margin-bottom: 30px;
  max-width: 500px;

  ${breakpoint("md")`
    margin-right: 40px;
  `}
`

const Hero = () => {
  const { hero, settings } = useStaticQuery(graphql`
    query HeroQuery {
      hero: dataJson(fileRelativePath: { eq: "/content/data/hero.json" }) {
        email
        title
        subtext
        imageDescription
        image {
          childImageSharp {
            fluid(maxWidth: 889) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        rawJson
        fileRelativePath
      }
      settings: dataJson(
        fileRelativePath: { eq: "/content/data/settings.json" }
      ) {
        email

        rawJson
        fileRelativePath
      }
    }
  `)

  const [heroValues] = useLocalJsonForm(hero, heroForm)
  const [settingsValues] = useGlobalJsonForm(settings, settingsForm)
  return (
    <Root>
      <Wrapper>
        <ImageHalf>
          <HeroImage>
            {!!heroValues.image?.childImageSharp?.fluid && (
              <Img
                fluid={heroValues.image.childImageSharp.fluid}
                alt={heroValues.imageDescription}
              />
            )}
          </HeroImage>
        </ImageHalf>
        <Half>
          <Title>{heroValues.title}</Title>
          <Subtext>{heroValues.subtext}</Subtext>
          <Button
            type="anchor"
            href={`mailto:${settingsValues.email}?subject=Get%20In%20Touch`}
            target="_blank"
            size="lg"
          >
            Contact Us
          </Button>
        </Half>
      </Wrapper>
    </Root>
  )
}

export default Hero

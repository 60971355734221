import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useLocalJsonForm } from "gatsby-tinacms-json"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Image from "./Image"
import imageSectionForm from "../../content/forms/image-section"
import FirstMountain from "../images/inline/first-mountain.svg"
import SecondMountain from "../images/inline/second-mountain.svg"
import ThirdMountain from "../images/inline/third-mountain.svg"
import squareDecoration from "../images/infinite-square.svg"

const Root = styled.section`
  max-width: 1360px;
  text-align: center;
  margin: 0 auto;
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 60px 0;
  ${breakpoint("md")`
    padding: 120px 0;
    flex-direction: row;
  `}
`

const Half = styled.div`
  padding: 0 20px;
  max-width: 540px;
  width: 100%;

  ${breakpoint("sm")`
    padding: 0 30px;
  `}

  ${breakpoint("md")`
    max-width: initial;
    width: 50%;
    text-align: ${props => props.align};
  `}

  ${breakpoint("md")`
    order: ${props => props.order};
  `}
`

const Mountain = styled.div`
  margin: 60px 0 30px;

  ${breakpoint("md")`
    margin-top: -60px;
  `}
`

const Paragraph = styled.p`
  margin-bottom: 40px;
`

const SectionImage = styled(Image)`
  max-height: ${props => props.maxHeight ?? "none"};
  max-width: ${props => props.maxWidth ?? "none"};
  margin-left: ${props => (props.align === "right" ? "auto" : 0)};

  &::before {
    content: "";
    position: absolute;
    background-image: url(${squareDecoration});
    background-size: 100%;
    width: 181px;
    height: 151px;
    bottom: -38px;
    ${props => {
      const amount = "-42px"
      const side = props.align === "right" ? "left" : "right"
      return `${side}: ${amount};`
    }};
  }
`

function getMountain(index) {
  switch (index % 3) {
    case 0:
      return <FirstMountain />
    case 1:
      return <SecondMountain />
    case 2:
    default:
      return <ThirdMountain />
  }
}

const ImageSection = () => {
  const { dataJson } = useStaticQuery(graphql`
    query ContentQuery {
      dataJson(fileRelativePath: { eq: "/content/data/image-section.json" }) {
        sections {
          id
          title
          subtext
          imageDescription
          image {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        rawJson
        fileRelativePath
      }
    }
  `)

  const [values] = useLocalJsonForm(dataJson, imageSectionForm)

  return (
    <Root>
      {values.sections.map((section, i) => {
        const odd = i % 2
        return (
          <Grid key={section.id}>
            <Half align={odd ? "right" : "left"} order={odd ? "1" : "2"}>
              <Mountain>{getMountain(i)}</Mountain>
              <h2>{section.title}</h2>
              <Paragraph>{section.subtext}</Paragraph>
            </Half>
            <Half align={odd ? "left" : "right"} order={odd ? "2" : "1"}>
              <SectionImage maxWidth="480px" align={odd ? "left" : "right"}>
                {!!section.image?.childImageSharp?.fluid && (
                  <Img
                    fluid={section.image.childImageSharp.fluid}
                    alt={section.imageDescription}
                  />
                )}
              </SectionImage>
            </Half>
          </Grid>
        )
      })}
    </Root>
  )
}

export default ImageSection

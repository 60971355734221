import get from "lodash.get"

export default {
  id: 1,
  label: "Hero",
  fields: [
    {
      name: "rawJson.title",
      component: "textarea",
      label: "Page Title",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.subtext",
      component: "textarea",
      label: "Subtext",
      description: "Add subtext for page title",
      parse(value) {
        return value || ""
      },
    },
    {
      label: "Image",
      name: "rawJson.image",
      component: "image",
      description: "889px X 500px",
      parse: filename => `../img/${filename}`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode || !imageNode.childImageSharp) return ""
        return imageNode.childImageSharp.fluid.src
      },
      uploadDir: () => `/content/img/`,
    },
    {
      label: "Image Description",
      description: "This adds to the websites SEO",
      name: "rawJson.imageDescription",
      component: "text",
      parse(value) {
        return value || ""
      },
    },
  ],
}
